// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AddCustomer from './components/AddCustomer';
import LoadPoints from './components/LoadPoints';
import RedeemPoints from './components/RedeemPoints';
import PrivateRoute from './components/PrivateRoute';
import Menu from './components/Menu';
import fondo from './assets/fondo.jpeg'


function App() {
  return (
    <Router>
      <img className='fondo' src={fondo}></img>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/menu"
          element={
            <PrivateRoute>
              <Menu />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-customer"
          element={
            <PrivateRoute>
              <AddCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/load-points"
          element={
            <PrivateRoute>
              <LoadPoints />
            </PrivateRoute>
          }
        />
        <Route
          path="/redeem-points"
          element={
            <PrivateRoute>
              <RedeemPoints />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
