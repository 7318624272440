// components/Menu.js
import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

function Menu() {
  const buttonCargarPuntos = useRef(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };
  
  useEffect( () =>{
    buttonCargarPuntos.current.focus()
  }, [])

  return (
    <div className="menu-container">
      <button className="logout-button" onClick={handleLogout}>
        Cerrar Sesión
      </button>
      <h2 className="title">Menú Principal</h2>
      <div className="menu-buttons">
        <button className="menu-button" onClick={() => navigate('/add-customer')}>
          Añadir Nuevo Cliente
        </button>
        <button className="menu-button" ref={buttonCargarPuntos} onClick={() => navigate('/load-points')}>
          Cargar Puntos
        </button>
        <button className="menu-button" onClick={() => navigate('/redeem-points')}>
          Canjear Puntos
        </button>
      </div>
    </div>
  );
}

export default Menu;
