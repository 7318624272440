// components/AddCustomer.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

function AddCustomer() {
  const inputDni = useRef(null);
  const inputNombre = useRef(null);
  const inputCel = useRef(null);
  const navigate = useNavigate();
  const [numeroDNI, setNumeroDNI] = useState('');
  const [nombreYApellido, setNombreYApellido] = useState('');
  const [numeroCelular, setNumeroCelular] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');

  // Funciones de validación
  function validarDNI(dni) {
    return /^\d{6,9}$/.test(dni);
  }

  function validarNombre(nombre) {
    return /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]{1,50}$/.test(nombre);
  }

  function validarCelular(celular) {
    return /^\d{1,20}$/.test(celular);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones
    if (!validarDNI(numeroDNI)) {
      setError('DNI inválido. Debe ser numérico y tener entre 6 y 9 dígitos.');
      setMensaje('');
      inputDni.current.focus()
      return;
    }

    if (!validarNombre(nombreYApellido)) {
      setError('Nombre y apellido inválido. Solo letras y máximo 50 caracteres.');
      setMensaje('');
      inputNombre.current.focus()
      return;
    }

    if (!validarCelular(numeroCelular)) {
      setError('Número de celular inválido. Solo números y máximo 20 caracteres.');
      setMensaje('');
      inputCel.current.focus()
      return;
    }

    try {
      const response = await api.post('/customers', {
        numeroDNI,
        nombreYApellido,
        numeroCelular,
      });
      setMensaje(response.data.message);
      setNumeroDNI('');
      setNombreYApellido('');
      setNumeroCelular('');
      setError('');
      inputDni.current.focus()
    } catch (err) {
      setError(err.response.data.message || 'Error al añadir el cliente.');
      setMensaje('');
      if (err.response.status === 403){
        setError('Sesión expirada. Debes iniciar sesión nuevamente')
      }
      inputDni.current.focus()
    }
  };

  // Función para manejar el evento cuando se presiona Enter
  const handleKeyDown = (e, nextInputRef) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evita el comportamiento predeterminado (enviar formulario, por ejemplo)
      nextInputRef.current.focus(); // Cambia el foco al siguiente input
    }
  };

  useEffect( () =>{
    inputDni.current.focus()
  }, [])

  return (
    <div className="container">
      <button className="return" onClick={() => navigate('/menu')}>
        Volver
      </button>
      <h2 className="title">Añadir Nuevo Cliente</h2>
      {error && <p className="error-message">{error}</p>}
      {mensaje && <p className="success-message">{mensaje}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          ref={inputDni}
          placeholder="DNI"
          value={numeroDNI}
          onChange={(e) => setNumeroDNI(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, inputNombre)}
        />
        <input
          type="text"
          ref={inputNombre}
          placeholder="Nombre y Apellido"
          value={nombreYApellido}
          onChange={(e) => setNombreYApellido(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, inputCel)}
        />
        <input
          type="text"
          ref={inputCel}
          placeholder="Número de Celular"
          value={numeroCelular}
          onChange={(e) => setNumeroCelular(e.target.value)}
        />
        <button type="submit">Añadir Cliente</button>
      </form>
    </div>
  );
}

export default AddCustomer;
