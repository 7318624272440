// components/LoadPoints.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

function LoadPoints() {
  const inputDni = useRef(null);
  const inputPuntos = useRef(null);
  const navigate = useNavigate();
  const [dni, setDni] = useState('');
  const [cliente, setCliente] = useState(null);
  const [puntosAgregados, setPuntosAgregados] = useState(1);
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');

  // Funciones de validación
  function validarDNI(dni) {
    return /^\d{6,9}$/.test(dni);
  }
  
  const buscarCliente = async () => {
    // Validaciones
    if (!validarDNI(dni)) {
      setError('DNI inválido. Debe ser numérico y tener entre 6 y 9 dígitos.');
      setMensaje('');
      inputDni.current.focus()
      return;
    }
    
    try {
      const response = await api.get(`/customers/${dni}`);
      setCliente(response.data);
      setMensaje('');
      setError('');
      setTimeout(
        function(){inputPuntos.current.focus()}
      , 100)
    } catch (err) {
      setError(err.response.data.message || 'Error al buscar el cliente.');
      setCliente(null);
      setMensaje('');
      if (err.response.status === 403){
        setError('Sesión expirada. Debes iniciar sesión nuevamente')
      }
    }
  };

  // Función de validación de puntos
  function validarPuntos(puntos) {
    const puntosInt = parseInt(puntos);
    return puntosInt >= 1 && puntosInt <= 10;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!cliente) {
      setError('Debe ingresar un DNI válido.');
      setCliente(null);
      setMensaje('');
      return;
    }

    if (!validarPuntos(puntosAgregados)) {
      setError('El número de facturas cobradas debe estar entre 1 y 10.');
      setMensaje('');
      return;
    }

    try {
      const response = await api.post('/points/load', {
        dni,
        puntosAgregados,
      });
      setMensaje(response.data.message + ' Puntos actuales del cliente ' + response.data.puntosNuevos + '.');
      setCliente(null);
      setPuntosAgregados(1);
      setDni('');
      setError('');
      inputDni.current.focus()
    } catch (err) {
      setError(err.response.data.message || 'Error al cargar puntos.');
      setMensaje('');
      if (err.response.status === 403){
        setError('Sesión expirada. Debes iniciar sesión nuevamente')
      }
    }
  };
  
  useEffect( () =>{
    inputDni.current.focus()
  }, [])

  return (
    <div className="container">
      <button className="return" onClick={() => navigate('/menu')}>
        Volver
      </button>
      <h2 className="title">Cargar Puntos</h2>
      {error && <p className="error-message">{error}</p>}
      {mensaje && <p className="success-message">{mensaje}</p>}
      <div>
        <form onSubmit={(e) => { e.preventDefault(); buscarCliente(); }}>
          <input
            type="text"
            ref={inputDni}
            placeholder="DNI"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
          />
          <button type="submit">Buscar Cliente</button>
        </form>
      </div>
      {cliente && (
        <div>
          <p>Nombre: {cliente.Nombreyapellido}</p>
          <p>Puntos Acumulados: {cliente.Puntosacumulados}</p>
          <form onSubmit={handleSubmit}>
            <input
              type="number"
              ref={inputPuntos}
              min="0"
              max="10"
              step="1"
              value={puntosAgregados}
              placeholder='Ingresa la cantidad de facturas cobradas'
              onChange={(e) => setPuntosAgregados(e.target.value)}
            />
            <button type="submit">Cargar Puntos</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default LoadPoints;
