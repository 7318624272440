// components/RedeemPoints.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

function RedeemPoints() {
  const inputDni = useRef(null);
  const navigate = useNavigate();
  const [dni, setDni] = useState('');
  const [cliente, setCliente] = useState(null);
  const [premios, setPremios] = useState([]);
  const [premioSeleccionado, setPremioSeleccionado] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');

  // Funciones de validación
  function validarDNI(dni) {
    return /^\d{6,9}$/.test(dni);
  }
  
  useEffect(() => {
    const obtenerPremios = async () => {
      try {
        const response = await api.get('/prizes');
        setPremios(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    obtenerPremios();
  }, []);

  const buscarCliente = async () => {
    // Validaciones
    if (!validarDNI(dni)) {
      setError('DNI inválido. Debe ser numérico y tener entre 6 y 9 dígitos.');
      setMensaje('');
      inputDni.current.focus()
      return;
    }
    
    try {
      const response = await api.get(`/customers/${dni}`);
      setCliente(response.data);
      setError('');
    } catch (err) {
      setError(err.response.data.message || 'Error al buscar el cliente.');
      setCliente(null);
      setMensaje('');
      if (err.response.status === 403){
        setError('Sesión expirada. Debes iniciar sesión nuevamente')
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!cliente) {
      setError('Debe ingresar un DNI válido.');
      return;
    }

    const premio = premios.find((p) => p.id === parseInt(premioSeleccionado));

    if (!premio) {
      setError('Debe seleccionar un premio válido.');
      return;
    }

    if (parseInt(cliente.Puntosacumulados) < parseInt(premio.Puntos)) {
      setError('Puntos insuficientes para canjear por este premio.');
      return;
    }

    try {
      const response = await api.post('/prizes/redeem', {
        dni,
        premioId: premio.id,
      });
      setMensaje(response.data.message);
      setCliente(null);
      setDni('')
      setError('');
      setPremioSeleccionado('')
      inputDni.current.focus()
    } catch (err) {
      setError(err.response.data.message || 'Error al cajear los puntos.');
      if (err.response.status === 403){
        setError('Sesión expirada. Debes iniciar sesión nuevamente')
      }
    }
  };
  
  useEffect( () =>{
    inputDni.current.focus()
  }, [])

  return (
    <div className="container">
      <button className="return" onClick={() => navigate('/menu')}>
        Volver
      </button>
      <h2 className="title">Canjear Puntos por Premios</h2>
      {error && <p className="error-message">{error}</p>}
      {mensaje && <p className="success-message">{mensaje}</p>}
      <div>
        <form onSubmit={(e) => { e.preventDefault(); buscarCliente(); }}>
          <input
            type="text"
            ref={inputDni}
            placeholder="DNI"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
          />
          <button type="submit">Buscar Cliente</button>
        </form>
      </div>
      {cliente && (
        <div>
          <p>Nombre: {cliente.Nombreyapellido}</p>
          <p>Puntos Acumulados: {cliente.Puntosacumulados}</p>
          <form onSubmit={handleSubmit}>
            <select
              value={premioSeleccionado}
              onChange={(e) => setPremioSeleccionado(e.target.value)}
            >
              <option value="">Seleccione un premio</option>
              {premios.map((premio) => (
                <option key={premio.id} value={premio.id}>
                  {premio.Premio} - {premio.Puntos} puntos
                </option>
              ))}
            </select>
            <button type="submit">Canjear Puntos</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default RedeemPoints;
