// services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://vps-3437464-x.dattaweb.com:8443/api',
  // baseURL: 'http://localhost:3001/api',
});

// Agregar un interceptor para incluir el token en las solicitudes
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
