// components/Login.js
import React, { useState, useRef, useEffect } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';


function Login() {
  const inputUser = useRef(null);
  const inputPass = useRef(null);
  const [usuario, setUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(usuario === ''){
      setError('Debe ingresar un nombre de usuario')
      inputUser.current.focus()
      return
    }
    if(contraseña === ''){
      setError('Debe ingresar una contraseña')
      inputPass.current.focus()
      return
    }

    try {
      const response = await api.post('/login', { usuario, contraseña });
      const token = response.data.token;
      // Almacenar el token en localStorage
      localStorage.setItem('token', token);
      navigate('/menu');
    } catch (err) {
      setError(err.response.data.message || 'Error al iniciar sesión.');
      inputUser.current.focus()
    }
  };

  // Función para manejar el evento cuando se presiona Enter
  const handleKeyDown = (e, nextInputRef) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evita el comportamiento predeterminado (enviar formulario, por ejemplo)
      nextInputRef.current.focus(); // Cambia el foco al siguiente input
    }
  };

  useEffect( () =>{
    inputUser.current.focus()
  }, [])
  
  return (
    <div className="container">
      <h2 className="title">Iniciar Sesión</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          ref={inputUser}
          placeholder="Usuario"
          value={usuario}
          onChange={(e) => setUsuario(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, inputPass)}
        />
        <input
          type="password"
          ref={inputPass}
          placeholder="Contraseña"
          value={contraseña}
          onChange={(e) => setContraseña(e.target.value)}
        />
        <button type="submit">Ingresar</button>
      </form>
    </div>
  );
}

export default Login;
